import { Container, Flex, Spinner, VStack } from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import Post from "./components/post";
import Navbar from "./components/navbar";
// import db from "./lib/firebase";

const App = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const url = "https://social-media-app.divyachalla95.workers.dev/posts";

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        console.log(json);
        setPosts(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  let postList = posts.sort((a,b) => {
    return ((a.upVotesCount - a.downVotesCount) > (b.upVotesCount - b.downVotesCount)) ? -1:1
  })
  console.log(postList);

  return (
    <>
      <Navbar posts={posts}/>
      <Container maxW="md" centerContent p={8}>
        <VStack spacing={8} w="100%">
          {postList.map((post) => (
            <Post post={post} key={post.id} posts={posts} />
          ))}
        </VStack>
      </Container>
    </>
  );
};

export default App;