import {
    Button,
    FormControl,
    FormLabel,
    Textarea,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    HStack,
    useDisclosure,
  } from "@chakra-ui/core";
  import React, { useState, useEffect } from "react";
//   import db from "../lib/firebase";
  
  const Comment = ({post, posts}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [comment, setComment] = useState("");
    const [isSaving, setSaving] = useState(false);
    const handleClick = async (type) => {
        // Do calculation to save the vote.
            let id = post.id;
            let upVotesCount = post.upVotesCount;
            let downVotesCount = post.downVotesCount;
    
            const date = new Date();
            const url = "https://social-media-app.divyachalla95.workers.dev/posts";
                const requestOptions = {
                    method: 'POST',
                    mode: 'no-cors',
                    headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST',
                    'content-type': 'application/json'
                    },
                    body: JSON.stringify({posts: posts.filter((item)=>{
                    return item.id != id
                    }).concat([{
                        id: post.id,
                        title: post.title,
                        body: post.body,
                        URL: post.URL,
                        comment: post.comment? post.comment.concat([comment]): [comment] ,
                        upVotesCount,
                        downVotesCount,
                        createdAt: post.createdAt,
                        updatedAt: date.toUTCString(),
                    }])})
                };
                try {
                    const response = fetch(url, requestOptions);
                    console.log(response);
                } catch (error) {
                    console.log("error", error);
                }
        onClose();
        setComment("");
        window.location.reload(false);
    };
  
    return (
      <>
        <Button onClick={onOpen} colorScheme="blue">
          Comment
        </Button>
  
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>Add comment</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl id="post-comment">
                  <FormLabel>Comment</FormLabel>
                  <Textarea
                    type="post-comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <HStack spacing={4}>
                  <Button onClick={onClose}>Close</Button>
                  <Button
                    onClick={handleClick}
                    colorScheme="blue"
                    disabled={!comment.trim()}
                    isLoading={isSaving}
                  >
                    Save
                  </Button>
                </HStack>
              </ModalFooter>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      </>
    );
  };
  
  export default Comment;