import { Box, Container, Flex, Heading } from "@chakra-ui/core";
import React from "react";
import AddNewPost from "./add-new-post";

const Navbar = ({ posts }) => {
  return (
    <Box position="sticky" top={0} p={4} bg="gray.100" zIndex={1}>
      <Container maxW="md" centerContent>
        <Flex justifyContent="flex-end" w="100%" position="sticky" top={0}>
          <Heading flex='auto'> CloudChat </Heading>
          <AddNewPost posts={posts}/>
        </Flex>
      </Container>
    </Box>
  );
};

export default Navbar;