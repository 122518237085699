import { IconButton, Text, VStack } from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import { BiSmile, BiSad } from "react-icons/bi";
// import db from "../lib/firebase";

const VoteButtons = ({ posts, post }) => {
    const [isVoting, setVoting] = useState(false);
    const [votedPosts, setVotedPosts] = useState([]);

    useEffect(() => {
        const votesFromLocalStorage = localStorage.getItem("votes") || [];
        let previousVotes = [];
    
        try {
          // Parse the value of the item from localStorage. If the value of the
          // items isn't an array, then JS will throw an error.
          previousVotes = JSON.parse(votesFromLocalStorage);
        } catch (error) {
          console.error(error);
        }
    
        setVotedPosts(previousVotes);
      }, []);
      const handleDisablingOfVoting = (postId) => {
        // This function is responsible for disabling the voting button after a
        // user has voted. Fetch the previously voted items from localStorage. See
        // https://stackoverflow.com/a/52607524/1928724 on why we need "JSON.parse"
        // and update the item on localStorage.
        const previousVotes = votedPosts;
        previousVotes.push(postId);
    
        setVotedPosts(previousVotes);
    
        // Update the voted items from localStorage. See https://stackoverflow.com/a/52607524/1928724 on why we need "JSON.stringify" and update the item on localStorage.
        localStorage.setItem("votes", JSON.stringify(votedPosts));
      };
    
      const checkIfPostIsAlreadyVoted = () => {
        if (votedPosts.indexOf(post.id) > -1) {
          return true;
        } else {
          return false;
        }
      };

    const handleClick = async (type) => {
    // Do calculation to save the vote.
        setVoting(true);
        let id = post.id;
        let upVotesCount = post.upVotesCount;
        let downVotesCount = post.downVotesCount;

        const date = new Date();

        if (type === "upvote") {
            upVotesCount = upVotesCount + 1;
        } else {
            downVotesCount = downVotesCount + 1;
        }

        const url = "https://social-media-app.divyachalla95.workers.dev/posts";
            const requestOptions = {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                'content-type': 'application/json'
                },
                body: JSON.stringify({posts: posts.filter((item)=>{
                return item.id != id
                }).concat([{
                    id: post.id,
                    title: post.title,
                    body: post.body,
                    URL: post.URL,
                    upVotesCount,
                    downVotesCount,
                    createdAt: post.createdAt,
                    updatedAt: date.toUTCString(),
                }])})
            };
            try {
                const response = fetch(url, requestOptions);
                console.log(response);
            } catch (error) {
                console.log("error", error);
            }
        handleDisablingOfVoting(post.id);
        setVoting(true);
        window.location.reload(false);
  };

  return (
    <>
      <VStack>
        <IconButton
          size="lg"
          colorScheme="purple"
          aria-label="Upvote"
          icon={<BiSmile />}
          onClick={() => handleClick("upvote")}
          isLoading={isVoting}
          isDisabled={checkIfPostIsAlreadyVoted()}
        />
        <Text bg="gray.100" rounded="md" w="100%" p={1}>
          {post.upVotesCount}
        </Text>
      </VStack>
      <VStack>
        <IconButton
          size="lg"
          colorScheme="yellow"
          aria-label="Downvote"
          icon={<BiSad />}
          onClick={() => handleClick("downvote")}
          isLoading={isVoting}
          isDisabled={checkIfPostIsAlreadyVoted()}
        />
        <Text bg="gray.100" rounded="md" w="100%" p={1}>
          {post.downVotesCount}
        </Text>
      </VStack>
    </>
  );
};

export default VoteButtons;